import { Divider } from "@mui/material";
import React from "react";
import lambdaProxyImage from "../../assets/images/lambda-proxy.png";
import Article from "../../components/Article";
import TextLink from "../../components/TextLink";

export const LambdaProxyDetails = {
  articleTitle: "Lambda Proxy",
  articleDate: "2024-09-13",
  articleAuthor: "Harrison Cannon",
  articleImage: lambdaProxyImage,
  articleBody: "Lambda Proxy: to enable or to not? Dive into the world of Lambda Proxy and make the best decision for your application.",
  articleSlug: "lambda-proxy"
};

function LambdaProxy() {
  function ArticleContent() {
    return (
      <div>
        <h2>Background</h2>

        <p>
          AWS Lambda is an AWS service that provides serverless compute within the AWS Cloud. It is commonly known as Function as a Service (FaaS) and you&apos;re only charged for what you use. Optionally, an AWS Lambda Function can reside within a VPC, giving you direct network access to other AWS resources you may have provisioned.
        </p>

        <h4>Benefits</h4>

        <ul>
          <li>
            Pay-As-You-Go (PAYG): Pay for what you use, rounded up to the nearest millisecond.
          </li>
          <li>
            Connect to other AWS resources such as Relational Database Service (RDS) and Elastic File System (EFS) as well as native integrations to other services using the AWS SDK.
          </li>
          <li>
            Fine-Grained Access Control (FGAC): As with any other AWS service, permissions to Lambda are provided by AWS Identity and Access Management (IAM).
          </li>
        </ul>

        <Divider className="divider" />

        <h2>What is Lambda Proxy</h2>

        <p>
          When using Lambda Proxy, API Gateway forwards the entire request from the client to the downstream Lambda Function. This allows for easier transformation of data using a developer&apos;s language of choice, rather than specifying in a mapping template.
        </p>

        <p>
          Likewise, the response from the Lambda Function is not modified either, meaning the response, including the status code and payload, are forwarded by API Gateway straight to the requesting client.
        </p>

        <p>
          With Lambda Proxy, a Lambda Function is required to return the below as an output:
        </p>

        <code>
          &#123;
          &quot;isBase64Encoded&quot;: true | false,
          &quot;statusCode&quot;: number,
          &quot;headers&quot;: &#123; key: &quot;value&quot; &#125;,
          &quot;body&quot;: &quot;JSON string&quot;
          &#125;
        </code>

        <Divider className="divider" />

        <h2>Should I be using Lambda Proxy Integration?</h2>

        <p>
          A common use case for a Lambda Proxy would be to define the HTTP methods you want to accept in API Gateway, and use separate Lambda Functions for each with custom responses that utilise the Lambda Proxy integration. This conforms, to AWS Best Practices in that each Lambda Function should be short, consise, and for a single purpose. For more AWS Lambda design patterns, see this <TextLink url="https://aws.amazon.com/blogs/compute/comparing-design-approaches-for-building-serverless-microservices" text="AWS Blog" />.
        </p>

        <p>
          For example, an API to retrieve all books from an online book store, should host a <code>GET</code> API in API Gateway with it&apos;s method integration set to a Lambda function that reads from the database. This Lambda integration should have the <code>Lambda Proxy</code> setting enabled.
        </p>

        <p>
          Once the books have been retrieved, the following code block would return the books to API Gateway as well as the status code which will be forwarded to the requesting client:
        </p>

        <code>
          return &#123;
          statusCode: 200,
          body: JSON.stringify(books)
          &#125;;
        </code>

        <p>
          If an error occured, such as the database being unreachable, or the Lambda Function had insufficient permissions, you could return an error using the below:
        </p>

        <code>
          return &#123;
          statusCode: 500,
          body: JSON.stringify(&#123; message: &quot;Unable to retrieve books&quot; &#125;)
          &#125;;
        </code>

        <p>
          Using the above strategies, you&apos;re then able to expand and add further API methods with Lambda Functions to retreieve specific books based on a title or author, or insert new books into database. Whatever the functionality, aslong as Lambda Proxy Integration is enabled, custom response codes and payloads, derived from the compute itself, can be used.
        </p>
      </div>
    );
  }

  return <Article
    article={LambdaProxyDetails}
    content={ArticleContent}
  />;
}

export default LambdaProxy;